import React from 'react';

import SEO from '../components/molecules/seo';
import HeroBanner from '../components/molecules/hero-banner-section';
import ServicesDetails from '../components/organisms/services-details';
import Testimonials from '../components/organisms/testimonials';
import FAQs from '../components/organisms/faqs';

const Servicios = () => (
  <>
    <SEO
      title="Nuestros servicios. Llámanos al 667 513 400 | JR Web"
      description="Somos especialistas en diseño y desarrollo web, tiendas e-commerce, aplicaciones móviles, maketing ditital. Llámanos al 667 513 400."
    />
    <HeroBanner
      hasDarkBackground
      content={{
        pretitle: 'Nos adaptamos a tus necesidades',
        title: '¿En qué podemos ayudarte?',
        description:
          'A continuación puedes ver la lista de nuestros principales servicios.',
      }}
    />
    <ServicesDetails />
    <Testimonials />
    <FAQs
      content={{
        title: 'Preguntas frecuentes',
        description:
          'Algunas de las cuestiones en las que más tienen dudas nuestros clientes, son las que se detallan a continuación con su respuesta.',
        questions: [
          {
            heading: '¿Qué se incluye en el servicio de Creación de páginas web?',
            description:
              'Ese servicio, incluye el registro de un dominio (.com, .es, .net, .org, ...), un hospedaje con un espacio de alojamiento de 1Gbyte (web y correo), 10 cuentas de correo POP3, diseño y maquetación de la web, con un formulario de toma de datos. El registro y el hospedaje de la web es por un año.',
          },
          {
            heading: '¿Es administrable mi web?',
            description:
              'Si cuando contratas con nosotros el servicio de creación de páginas web, indicas que quieres tener la web autoadministrada, te añadiremos un gestor de contenidos con el que puedas tener el control del 100% de tu web para que, una vez puesta en marcha, puedas administrarla completamente.',
          },
          {
            heading: '¿Cómo se posiciona mi web en los buscadores?',
            description:
              'Para conseguir un buen posicionamiento en buscadores o SEO (Search Engine Optimization), hay que realizar una serie de operaciones en su página web, tanto a nivel de código como a nivel de contenido. Nuestro servicio de posicionamiento puede ayudarle a conseguir sus objetivos. Consúltenos para más información.',
          },
          {
            heading: '¿Qué debo hacer si necesito una tienda online para vender productos o servicios?',
            description:
              'Tenemos un desarrollo propio, la tienda de productos CFlexTienda, que es 100% adaptable a sus necesidades. Con este servicio, le pondremos en funcionamiento en Internet, una tienda de productos o servicios, totalmente autoadministrada. El hecho de que sea un desarrollo propio, le brinda la posibilidad de adaptarla completamente a su gusto o a sus requisitos.',
          },
          {
            heading: 'Una vez puesta en marcha mi web, ¿si necesito cambiar de diseño o añadir más funcionalidades?',
            description:
              'Nuestra experiencia de más de 20 años trabajando en Internet, nos permite personalizar todos nuestros trabajos a cualquier necesidad que tengan nuestros clientes. Puede contratar nuestro servicio de mantenimiento web, ajustado al número de horas que necesite, para tener siempre nuestra ayuda y respuesta.',
          },
        ],
      }}
    />
  </>
);

export default Servicios;
