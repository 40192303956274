import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { slideInFromBottom } from '../../utils/animations';

import Prelude from '../molecules/prelude';
import Accordion from '../molecules/accordion';
import styles from './faqs.module.scss';

const FAQs = ({ content }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-40px',
  });

  const sdFAQ = (heading, description) => `{
    "type": "Question",
    "name": "${heading}",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "${description}"
    }
  }`;

  return (
    <>
      <motion.section
        className={styles.container}
        ref={ref}
        animate={inView ? 'visible' : 'hidden'}
        initial="hidden"
        variants={slideInFromBottom}
      >
        <Prelude
          content={{
            pretitle: content.pretitle,
            title: content.title,
            description: content.description,
          }}
        />

        <div className={styles.accordion}>
          <Accordion content={{ rows: content.questions }} />
        </div>
      </motion.section>

      {/* Structured data for FAQs */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{"@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [${content.questions.map(
            (faq) => sdFAQ(faq.heading, faq.description)
          )}]}`,
        }}
      />
    </>
  );
};

FAQs.propTypes = {
  content: PropTypes.shape({
    pretitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default FAQs;
