import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styles from './accordion.module.scss';

const AccordionComponent = ({ content }) => (
  <Accordion className={styles.container} allowZeroExpanded>
    {content.rows.map((row, index) => (
      <AccordionItem key={index} className={styles.row}>
        <AccordionItemHeading>
          <AccordionItemButton className={styles.question}>
            {row.heading}
            <span>+</span>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className={styles.answer}>
          <p>{row.description}</p>
        </AccordionItemPanel>
      </AccordionItem>
    ))}
  </Accordion>
);

AccordionComponent.propTypes = {
  content: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default AccordionComponent;
