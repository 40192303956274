import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { slideInFromBottom } from '../../utils/animations';

import Prelude from '../molecules/prelude';
import Tags from '../molecules/tags';
import styles from './services-details.module.scss';

const ServicesDetails = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px',
  });

  const images = useStaticQuery(graphql`
    query {
      paginasWebImage: file(relativePath: { eq: "creacion-webs.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tiendasImage: file(relativePath: { eq: "tiendas-web.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      movilesImage: file(relativePath: { eq: "aplicacion-cfutbol-actas.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gestorImage: file(relativePath: { eq: "logos-cfutbol.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dominiosImage: file(relativePath: { eq: "dominios-hospedajes.png" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <motion.section
      className={styles.container}
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      initial="hidden"
      variants={slideInFromBottom}
    >
      <Prelude
        content={{
          title: 'Diseño, programación y herramientas web',
        }}
      />

      <div className={styles.services}>
        <div className={styles.service}>
          <div className={styles.content}>
            <h2>Creación de páginas web</h2>
            <p>
              Llevamos más de 20 años diseñando y maquetando páginas web. Desde
              webs estáticas en formato HTML a programación específica según las
              necesidades de nuestros clientes. Consúltanos qué posibilidades de
              desarrollo te ofrecemos.
            </p>
          </div>
          <div className={styles.tags}>
            <Tags items={['.NET', 'Umbraco', 'Sass', 'HTML', 'CSS']} />
          </div>
          <div className={styles.image}>
            <Img
              fluid={images.paginasWebImage.childImageSharp.fluid}
              alt="Diseño y programación web"
            />
          </div>
        </div>

        <div className={styles.service}>
          <div className={styles.content}>
            <h2>Tiendas E-commerce</h2>
            <p>
              Hemos desarrollo un productos propio,
              <b>CFlexTienda</b>, que puede integrarse en nuestros desarrollos
              web y permite la venta de productos en formato E-commerce. Todo el
              control que necesita para la venta de servicios o productos a
              través de la web.
            </p>
          </div>
          <div className={styles.tags}>
            <Tags items={['CFlexTienda', '.NET', 'Angular']} />
          </div>
          <div className={styles.image}>
            <Img
              fluid={images.tiendasImage.childImageSharp.fluid}
              alt="Tienda de venta de productos"
            />
          </div>
        </div>

        <div className={styles.service}>
          <div className={styles.content}>
            <h2>Aplicaciones móviles</h2>
            <p>
              Apoyándonos en el framework de desarrollo{' '}
              <a href="https://ionicframework.com/">IONIC</a>, confeccionamos
              aplicaciones para entorno móvil, que pueden publicarse en las
              plataformas de <b>iOS</b> (AppStore) y de <b>Android</b> (Google
              Play).
            </p>
          </div>
          <div className={styles.tags}>
            <Tags items={['IONIC', 'iOS', 'Android', 'Angular', 'Sass']} />
          </div>
          <div className={styles.image}>
            <Img
              fluid={images.movilesImage.childImageSharp.fluid}
              alt="Aplicación CFutbolActas"
            />
          </div>
        </div>

        <div className={styles.service}>
          <div className={styles.content}>
            <h2>Gestor deportivo</h2>
            <p>
              Utilizado por la Federación Interinsular de Fútbol de Las Palmas,
              por la Federación Interinsular de Fútbol de Tenerife y por algunos
              campeonatos deportivos, nuestro gestor deportivo <b>CFutbol</b> le
              permite administrar competiciones de fútbol en todo su nivel.
            </p>
          </div>
          <div className={styles.tags}>
            <Tags items={['CFutbol', '.NET', 'Intranet']} />
          </div>
          <div className={styles.image}>
            <Img
              fluid={images.gestorImage.childImageSharp.fluid}
              alt="Logos Gestor Deportivo - CFutbol"
            />
          </div>
        </div>

        <div className={styles.service}>
          <div className={styles.content}>
            <h2>Dominios y hospedajes web</h2>
            <p>
              Como servicio asociado a la puesta en funcionamiento de una web en
              Internet, es necesario contar con el servicio de contratación de
              dominios y alojamiento web en Internet. Despreocúpese por dichas
              gestiones, nosotros se lo ponemos fácil.
            </p>
          </div>
          <div className={styles.tags}>
            <Tags items={['dominio', 'hospedaje']} />
          </div>
          <div className={styles.image}>
            <Img
              fluid={images.dominiosImage.childImageSharp.fluid}
              alt="Contratación de dominios y hospedajes"
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default ServicesDetails;
