import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  slideInFromBottom,
  containerShowOnLanding,
} from '../../utils/animations';

import Prelude from '../molecules/prelude';
import Testimonial from '../molecules/testimonial';
import styles from './testimonials.module.scss';

const Testimonials = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-40px',
  });

  return (
    <motion.section
      className={styles.container}
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      initial="hidden"
      variants={slideInFromBottom}
    >
      <Prelude
        content={{
          title: 'Testimonios',
        }}
      />

      <motion.div
        className={styles.grid}
        animate={inView ? 'visible' : 'hidden'}
        initial="hidden"
        variants={containerShowOnLanding}
      >
        <motion.div className={styles.gridItem} variants={slideInFromBottom}>
          <Testimonial
            withoutPanel
            content={{
              quote:
                'La implementación del sistema CFútbol, realizada por la empresa JRWEB, ha significado un antes y después en la Gestión Integral del Comité Técnico de Árbitros de la Federación Interinsular de Fútbol de Las Palmas. Partiendo de cero, buscando la idoneidad para cada departamento, creando un sistema de gestión integral para toda la Federación. La herramienta que ha desarrollado ha simplificado las tareas diarias reportando a todos los afiliados un beneficio sobresaliente.',
              author: {
                pic: false,
                name: 'Pedro Juan Díaz Batista',
                role: 'Presidente Comité Técnico de Árbitros FIFLP',
              },
            }}
          />
        </motion.div>
        <motion.div className={styles.gridItem} variants={slideInFromBottom}>
          <Testimonial
            withoutPanel
            content={{
              quote:
                'JRweb nos ha ayudado a optimizar todos nuestros procesos. Su valor agregado es la relación directa y personal con un excelente trato. Trabajar con esta empresa es tener en tu equipo a un socio de confianza, responsable con los plazos, resolutivo y flexible con los cambios.',
              author: {
                pic: false,
                name: 'Giover Alegre. Departamento de Licencias',
                role: 'Federación Interinsular de Futbol de Tenerife',
              },
            }}
          />
        </motion.div>
        <motion.div className={styles.gridItem} variants={slideInFromBottom}>
          <Testimonial
            withoutPanel
            content={{
              quote:
                'El factor inmediatez es clave en el entorno actual. La gestión del desarrollo web realizado por JRWEB es crucial para nosotros, obteniendo así un incremento paulatino de nuestros resultados online. Muy recomendable.',
              author: {
                pic: false,
                name: 'Dirección Marketing y Ventas, Área de La Gomera',
                role: 'Fred. Olsen, S.A.',
              },
            }}
          />
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default Testimonials;
